.login {
  font-family: "Lato", sans-serif;
  color: white;

  h1 {
    font-family: "Montserrat", sans-serif;
    color: white;
    text-align: center;
  }

  background-color: $navy;
  background-image: url('../images/browning_wash.jpg');
  background-position: top;
  background-size: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;

  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 5rem;

  .login-header {
    text-align: center;
    img {
      max-width: 60%;
    }
  }

  .login-content {
    label {
      font-family: "Montserrat", sans-serif;
      text-transform: uppercase;
      color: white;
    }
    input[type=text],
    input[type=password] {
      background-color: transparent;
      border: 0;
      border-bottom: 3px solid white;
      border-radius: 0;
      color: white;
    }
    button {
      border-radius: 0;
      border: 1px solid white;
      font-family: "Montserrat", sans-serif;
      text-transform: uppercase;
      margin: auto;
    }
  }
}
