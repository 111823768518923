@import url('https://fonts.googleapis.com/css?family=Lato|Montserrat');

@import '_colours';
@import '_login';

body {
  font-family: "Lato", sans-serif;
}

h1,h2,h3,h4,h5 {
  font-family: "Montserrat", sans-serif;
  x-font-family: "Alte DIN 1451 Mittelschrift", sans-serif;
  color: $navy;
  font-weight: 600;
}

.h1,
h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
}

.h2,
h2 {
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.h3,
h3 {
  font-size: 1.1rem;
  x-text-transform: uppercase;
}

.fa-red {
  color: $red;
}

.fa-green {
  color: $green;
}

.fa-yellow {
  color: $yellow;
}

.fa-grey {
  color: $light_grey;
}

.btn-group-toggle,
.btn-group {
  .btn-primary {
    &:not(.active):not(.disabled) {
      background-color: $grey;
      color: white;
    }
  }
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: $grey;
}

label {
  x-font-style: italic;
  color: $dark_grey;
}

.nowrap,
.no-wrap {
  white-space: nowrap;
}

::placeholder {
  font-style: italic;
  color: $light_grey !important;
}

.form-control-sm {
  font-size: 0.75rem;
}

.form-notes {
  font-size: 0.9rem;
}

.narrow-gutters > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.head {
      background-color: $bright_blue;
      color: white;
      font-family: "Montserrat", sans-serif;
      padding: 0.3rem 0.75rem;
      text-transform: uppercase;
      white-space: nowrap;
    }

/***** NOTIFICATIONS *****/

.notifications {
  position: fixed;
  z-index: 1;
  font-size: 14px;
  width: 100%;
  margin: auto;

  div {
    z-index: 999999;

    p {
      margin-bottom: 3px;
    }
  }
}


/***** COMPONENTS *****/

.featherlight-previous,
.featherlight-next {

  width: 5rem;
  x-background-color: green;

  &:hover {
    background-color: rgba(255,255,255,.75);
    border-right: 1px solid $light_grey;
    border-left: 1px solid $light_grey;
  }

  span {
    color: black;
    font-size: 3rem;
  }
}

.featherlight-previous {
  left: 0;
}

.featherlight-next {
  right: 0;
  left: auto;
}


.gj-picker {
  font-family: "Lato", sans-serif;
}


/***** HEADER *****/

header {
  background-color: $navy;
  color: white;

  .logo {
    height: 64px;
    padding: 5px 10px;

    img {
      max-width: 99%;
      height: 54px;
    }

    .btn {
      margin-top: 6px;
      border-radius: 0;
    }

  }

  nav.navbar {
    font-size: 0.85rem;
    background-color: white;
    border-bottom: 1px solid $light_grey;
    padding: 0;

    div.navbar-collapse {
      padding: 0.5rem 1rem;
    }

  }
}


@media (min-width: 992px) {
  header {
    nav.navbar {
      div.navbar-collapse {
        x-padding: 0;
        .navbar-nav li.nav-item a {
          padding: 0 1rem;
        }
      }
    }
  }
}

.content {
  margin-top: 1rem;
  margin-bottom: 1rem;

  fieldset {
    border-top: 1px solid $bright_blue;
    border-bottom: 1px solid $bright_blue;
    padding: 0.75rem;
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;

    background-color: #f8f9fa;

    legend {
      font-family: "Montserrat", sans-serif;
      font-size: 0.9rem;
      width: auto;
      padding: 0 1rem;
      text-transform: uppercase;
      margin-bottom: 0;
      font-weight: 600;
      color: $bright_blue;
    }
  }

  label {
    margin-bottom: 0;
    margin-top: 0.5rem;
  }

  .btn {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-size: 0.8rem;
    border-radius: 0;
    x-margin: 0.5rem 0;
  }

  .toggle {
    &.btn {
      margin: auto;
    }

    .toggle-group {
      .btn {
        margin: auto;

        &.btn-xs {
          font-size: 0.6rem;
          padding-top: 0.55rem;

          &.toggle-on {
            padding-left: 0.35rem;
          }

          &.toggle-off {
            padding-left: 0.65rem;
          }
        }
      }
    }
  }

  .input-group-append .btn {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    margin: 0;

    i {
      top: 7px;
    }
  }
}


/***** FILTERS *****/

fieldset.filters {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  padding: 0 1rem;
  padding-bottom: 0.5rem;

  border-top: 1px solid $light_grey;
  border-bottom: 1px solid $light_grey;

  legend {
    font-size: 0.7rem;
    margin: 0;
  }

  .form-group {
    margin-bottom: 0;
  }

  .form-control {
    font-size: 0.8rem;
  }

  label {
    margin: 0;
  }
}

@media (max-width: 767px) {
  fieldset.filters {

    padding-bottom: 0.2rem;

    label,
    .form-control {
      margin-bottom: 0.3rem;
    }

    .input-group {
      margin-bottom: 0.3rem;
      .form-control {
        margin-bottom: 0;
      }
    }

  }
}



/***** TABLES *****/

.list {

  width: 100%;
  border-bottom: 1px solid $bright_blue;
  font-size: 0.8rem;

  .head {

    div,
    td, th {
      background-color: $bright_blue;
      color: white;
      font-family: "Montserrat", sans-serif;
      padding: 0.3rem 0.75rem;
      text-transform: uppercase;
      white-space: nowrap;
    }

    th.tablesorter-header {
      cursor: pointer;
    }

    th div.tablesorter-header-inner:after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      padding-left: 0.5rem;
      vertical-align: middle;
    }

    th.tablesorter-headerUnSorted div.tablesorter-header-inner:after {
      content: "\f0dc";
    }

    th.tablesorter-headerAsc div.tablesorter-header-inner:after {
      content: "\f0de";
    }

    th.tablesorter-headerDesc div.tablesorter-header-inner:after {
      content: "\f0dd";
    }

  }

  .list-row {

    &.odd:hover,
    &.warning:hover,
    &:hover {
      background-color: $light_blue;
      x-color: white;

      a {
        x-color: white;
      }
    }

    >div,
    td {
      padding: 0.3rem 0.75rem;

      img {
        max-height: 50px;
      }

      .btn {
        margin: 0;
        .fa {
          margin: 0;
        }
      }
    }

    &.odd {
      background-color: #eee;
    }

    &.divider-row {
      border-top: 1px solid black;
    }

    >div {
      padding: 0.1rem 0.3rem;
    }

    &.warning {
      background-color: $lt_orange;
      border-bottom: 1px solid $orange;

      .btn-outline-primary {
        background-color: white;

        &:hover {
          background-color: $lt_orange;
        }
      }
    }

  }

}

div.list {
  border-bottom: 0;
}

/***** E-MAIL *****/

.email {
  width: 650px;

  .email-header {
    border-bottom: 1px solid $light_grey;
    padding-bottom: 1rem;
    h2 {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
    p {
      margin-bottom: 0;
    }
  }

  .email-body {
    padding: 1rem;
    border-bottom: 1px solid $light_grey;
  }

  .email-data {
    h3 {
      margin-top: 1rem;
    }
    p {
      margin-bottom: 0;
    }
  }
}


/***** GALLERY *****/

.gallery-select-albums {

  max-height: 500px;
  overflow: auto;
  margin-top: 1rem;

  .gallery-select-notes {
    border: 1px solid $light_grey;
    border-radius: 6px;
    padding: 5px;
    margin: 10px;
    font-size: 0.8rem;
  }

  .gallery-select-album-image-container {
    float: left;
    width: 300px;
    height: 240px;
    overflow: hidden;
    x-border: 1px solid $light_grey;
    x-background-color: $light_grey;
    padding: 5px;
    margin: 10px;
    border-radius: 6px;

    &.active {
      box-shadow: 0 0 7px 0px $bright_blue;
    }

    .gallery-select-album-image {
      text-align: center;
      height: 150px;

      img {
        border: 1px solid $light_grey;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .gallery-select-album-image-filename {
      white-space: nowrap;
      font-size: 0.8rem;
      padding: 5px 3px;
      margin: 0 1rem;
    }

    .gallery-select-album-image-highlight,
    .gallery-select-album-image-input {
      font-size: 0.8rem;
      margin: 0 1rem;

      span {
        margin-right: 1rem;
      }
    }
  }

}

.gallery-album-container {

  .gallery-album {
    float: left;
    height: 235px;
    width: 300px;
    overflow: hidden;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border: 1px solid $light_grey;

    box-shadow: 1px 1px 5px $light_grey;

    &.no-delete {
      height: 190px;
    }

    &.active {
      box-shadow: 0 0 7px 0px $bright_blue;
    }

    .gallery-album-image {
      height: 150px;
      text-align: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    a:hover {
      text-decoration: none;
    }

    .gallery-album-name {
      color: $dark_grey;
      font-size: 0.8rem;

      div {
        color: $dark_grey;
      }
    }
  }

}

#gallery-album-upload-files {

  .gallery-album-upload-file-preview {
    width: 150px;
    height: 120px;
    overflow: hidden;
    border: 1px solid $light_grey;
    text-align: center;
    padding: 0.5rem;
    float: left;
    margin-right: 0.5rem;

    box-shadow: 1px 1px 5px $light_grey;

    img {
      max-height: 80%;
      max-width: 100%;
    }

    div {
      font-size: 0.8rem;
    }
  }

}


/***** FILE LIST FORM *****/

.file-name {
  .preview {
    padding: 10px;
    img {
      max-width: 100%;
      max-height: 105px;
    }
  }
}


/***** HERO ALBUM FORM *****/

.form-image-page {
  border: 1px solid $light_grey;
  box-shadow: 1px 1px 5px $light_grey;

  hr {
    display: none;
  }

  .placeholder {
    background-color: $light_grey;
    box-shadow: 1px 1px 5px $light_blue;
  }

  .dragging {
    background-color: white;
    z-index: 9;
  }

  .form-image-row {
    padding: 0.75rem 0.25rem;
    border-bottom: 1px solid $light_grey;
    margin: 0;

    .row {
      margin-bottom: 0.25rem;
    }

    .form-image {
      img {
        max-height: 250px;
        max-width: 100%;
        border: 1px solid $light_grey;
      }

      div {
        font-size: 0.8rem;
        margin-bottom: 0.5rem;
      }
    }
  }

}


/***** AMENITIES *****/

#amenities_data {

  height: 1000px;
  overflow: auto;
  padding-top: 2px;

  .amenity-div {
    border: 1px solid $lt_grey;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 15px;

    &.selected {
      box-shadow: 0px 0px 1px 2px #0ff;
    }

    .amenity-name {
      font-weight: 600;
    }

    .amenity-buttons {
      text-align: right;

      button {
        margin-left: 5px;
      }

      .highlight-star {
        font-size: 1.5rem;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 0.3rem;

        &:hover {
          color: $yellow;
        }
      }
    }
  }

}
