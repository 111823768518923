$navy: #15324d;
$dark_blue: $navy;
$dk_blue: $navy;

$bright_blue: #007bff;
$light_blue: #c6e2ff;

$grey: #999;
$light_grey: #ccc;
$lt_grey: $light_grey;
$dark_grey: #777;
$dk_grey: $dark_grey;

$green: #009933;
$red: #af272f;

$orange: #cc9a1b;
$lt_orange: #f0d58e;

$yellow: #ffd500;
